@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");
@font-face {
  font-family: "Cafe24Ohsquare";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Cafe24Ohsquare.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  box-sizing: border-box;
}

#root {
  position: static !important;
}
button:focus {
  outline: 0;
}
nav {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8vh;
}
nav img {
  height: 50%;
}

.slick-vertical .slick-slide {
  border: 0 !important;
}
.main {
  height: 91.7vh;
  position: relative;
}

.main1 {
    background: url("image/main1.jpg") no-repeat;
    background-size: 100% 100%;
    background-position: center top;
}

.main2 {
    background: url("image/main2.jpg") no-repeat;
    background-size: 100% 100%;
    background-position: center top;
}

.main3 {
    background: url("image/main3.jpg") no-repeat;
    background-size: 100% 100%;
    background-position: center top;
}

.main > div {
  position: absolute;
  bottom: 7%;
}

.main div div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main button {
  width: 141px;
  height: 40px;
  border-radius: 7px;
  border: 0;
  font-size: 18px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  margin-left: 13px;
}
.main button:nth-child(2) {
  background: rgb(184, 120, 41);
  background: linear-gradient(
    180deg,
    rgba(184, 120, 41, 1) 0%,
    rgba(184, 120, 41, 1) 31%,
    rgba(152, 100, 37, 1) 100%
  );
}
.main button:first-child {
  background: rgb(106, 70, 44);
  background: linear-gradient(
    180deg,
    rgba(106, 70, 44, 1) 0%,
    rgba(106, 70, 44, 1) 45%,
    rgba(92, 60, 38, 1) 100%
  );
  margin-left: 0;
}
/*메인 화살표*/
.demo a {
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}

#section07 a {
  margin-top: 15px;
}
#section07 a p {
  font-family: "GmarketSansMedium";
  letter-spacing: -0.3px;
  font-size: 15px;
}
#section07 a div {
  display: inline;
  margin-top: 20px;
}
#section07 a span {
  position: absolute;
  top: 20px;
  left: 60%;
  width: 16px;
  height: 16px;
  margin-left: -12px;
  border-left: 1px solid #333;
  border-bottom: 1px solid #333;
  -webkit-transform: rotate(-45deg) skew(20deg, 20deg);
  transform: rotate(-45deg) skew(10deg, 10deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#section07 a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#section07 a span:nth-of-type(2) {
  top: 25px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
#section07 a span:nth-of-type(3) {
  top: 30px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

section h1 {
  letter-spacing: -0.3px;
  font-size: 15px;
  font-weight: 400;
  padding-top: 20px;
  z-index: 2;

  color: #333;
  text-align: center;
  white-space: nowrap;
}
/*메인화살표 두번째*/
.mainsecond a span {
  left: 5px !important;
  transform: rotate(135deg) skew(10deg, 10deg) !important;
  border-left: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}
.mainsecond h1,
.mainsecond p {
  color: #fff !important;
  padding-top: 0;
}

.mainsecond a span:nth-of-type(1) {
  -webkit-animation-delay: 0.3s !important;
  animation-delay: 0.3s !important;
}
.mainsecond a span:nth-of-type(2) {
  -webkit-animation-delay: 0.15s !important;
  animation-delay: 0.15s !important;
}
.mainsecond a span:nth-of-type(3) {
  -webkit-animation-delay: 0s !important;
  animation-delay: 0s !important;
}

.videoBtns p {
  color: #fff;
  opacity: 0.4;
}

.mainbtns {
  all: unset;
  cursor: pointer;
  margin: 5px 5px 10px 5px;
}

.selectbtn {
  border: 3px solid white;
}

.phoneN {
  height: 50px;
  text-align: 50px;
  background: #ccc;
  color: #878787;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 10px;
}

@media all and (min-width: 600px) {
  .phoneN {
    font-size: 20px;
    height: 60px;
  }
  .main button {
    width: 200px;
    height: 50px;
    font-size: 20px;
  }

  .now,
  .wait {
    height: 35px !important;
    font-size: 17px;
    line-height: 35px !important;
  }

  input::placeholder {
    font-size: 20px;
  }
  /*
  input[type="text"],
 .search-bar {
    font-size: 20px !important;
  }
  .inputwrapdiv {
    height: 60px !important;
  }*/

  .agree {
    font-size: 17px !important;
    line-height: 25px !important;
  }

  .control {
    padding-left: 50px !important;
  }

  .control__indicator {
    top: 12px !important;
    height: 25px !important;
    width: 25px !important;
  }
  .control--checkbox .control__indicator:after {
    left: 10px !important;
    top: 5px !important;
  }

  .name {
    font-size: 20px !important;
  }
  .age {
    font-size: 20px !important;
  }
  .sub-header {
    font-size: 15px !important;
  }
  .search-preview {
    height: 60px !important;
  }

  .cancel-btn {
    height: 60px !important;
    width: 60px !important;
  }

  .cancel-btn img {
    width: 50%;
  }

  /* .css-yk16xz-control div {
      overflow: unset !important;
      font-size: 25px !important;
  } */

  .aca div {
    font-size: 20px !important;
  }

  .alram {
    margin-top: 5px;
    font-size: 20px;
    margin-bottom: 5px;
  }

  .isWait {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 20px !important;
  }

  .remain {
    font-size: 20px !important;
  }
  .result > p {
    font-size: 17px !important;
  }
  .result > div > p {
    font-size: 17px !important;
  }
  .result > div > span {
    width: 130px !important;
    font-size: 17px !important;
  }
  .result > div {
    line-height: 40px !important;
  }

  .point {
    font-size: 25px !important;
  }

  .num_Div {
    font-size: 17px !important;
    height: 40px !important;
    width: 300px !important;
    line-height: 40px !important;
  }

  .guide {
    font-size: 17px !important;
  }

  .info {
    padding: 0 16px;
  }
  .info > img {
    max-width: 500px;
    margin: 0 auto;
  }
  .info > h1 {
    font-size: 16px;
    font-weight: 700;
    margin: 25px 0 10px 0;
  }
  .info > h2 {
    font-size: 20px !important;
    font-weight: 700;
    text-align: left;
    margin-top: 15px;
  }
  .info > h6 {
    margin-top: 20px;
    font-size: 20px !important;
    color: #b4b4b4;
    font-weight: 400;
    text-align: left;
  }
  .info > p,
  .info h2 p {
    font-size: 20px !important;
    font-weight: 400;
    text-align: left;
  }
  /*
  .slick-slider {
    position: static;
  }
  */
  .slick-dots {
    position: static !important;
  }
  p > b {
    color: #e60012;
  }
  .over > h2 {
    background: #fff59d;
    padding-left: 10px;
  }

  .info li {
    font-size: 20px !important;
    margin-bottom: 5px;
  }
  .info ol {
    padding-left: 20px;
  }

  .space {
    margin-right: 38px !important;
  }

  .onebtn {
    height: 60px !important;
    font-size: 25px !important;
  }
}




.thema1 {
    background-color: rgb(51, 156, 193);
}

.thema2 {
    background-color: rgb(71, 173, 135);
}

.thema3 {
    background-color: rgb(51, 156, 193);
}