.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.control-group {
  text-align: left;
  border: 1px solid #ccc;
  height: 50px;
  width: 100%;
}
.more {
  position: absolute;
  right: 0;
  height:95%;
  width:40px;
  border: 0;
  font-size: 15px;
  background: #fff;
  text-decoration: underline;
}
.control {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  font-size: 17px;
  line-height: 50px;
  height: 100%;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 14px;
  left: 10px;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}

.control input:checked ~ .control__indicator {
  background: #61890e;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #61890e;
}
.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select select::-ms-expand {
  display: none;
}
.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}
.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}

.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}
.agree {
  border: 1px solid #ccc;
  border-top: 0;
  padding: 5px 10px;
  text-align: left;
  line-height: 20px;
  height: 325px;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 14px;
}
