.cmath_Cell_Head {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.cmath_Cell_Body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  word-break: break-all;
  padding: 5px;
  box-sizing: border-box;
  overflow: auto;
}

.grade_Color_Div {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  height: 27px;
  min-height: 27px;
}

.grade_Color_Back {
  position: relative;
  width: 80px;
  height: 27px;
  background-color: #a7a7a7;
  border-radius: 100px;
  transition: all 0.5s;
  border: 1px solid #ccc;
}

.grade_Color_Toggle {
  position: absolute;
  left: 2%;
  width: 23px;
  height: 23px;
  /* background-color: white; */
  border-radius: 100px;
  transition: all 0.5s;
  box-sizing: border-box;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #ccc;
  background: #fff;
  top: 2px;
  /*background: radial-gradient(rgb(255, 255, 255, 1) 50%, rgba(240, 240, 240, 1) 70%, rgba(225, 225, 225, 1) 100%);*/
  /* border-color: white white white white; */
  /*box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.1);*/
  /* box-shadow: 0 1px 3px rgba(107, 106, 106, 0.26), 0 5px 1px rgba(107, 106, 106, 0.13); */
}

.grade_Color_Text {
  position: absolute;
  color: white;
  font-weight: bold;
  transition: all 0.5s;
  top: 2px;
  left: 50%;
}

.cmath_Cell_Body webkit-scrollbar {
  width: 30px;
  background: red;
}
