* {
  box-sizing: border-box;
  color: #333;
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");
@import url("https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css");

::placeholder {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 17px;
  line-height: 50px;
  color: #878787;
}
.css-1wa3eu0-placeholder {
  font-size: 17px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select:focus {
  outline: 0;
}

.check {
  height: auto;
  padding: 1.6em 0;
}

input[type="text"],
.search-bar {
  border-radius: 0;
  appearance: none;
  border: 1px solid #cccccc;
  height: 50px;
  line-height: 50px;
  font-size: 17px;
  /*margin-bottom: 10px;*/
  padding-left: 7px;
}
input#school {
  margin-bottom: 0;
}
input[type="radio"] {
  display: none;
}
input:active {
  outline: 0;
}
input:focus {
  outline: 0;
}
button {
  font-family: "Noto Sans KR", sans-serif;
}
body {
  font-family: "Noto Sans KR", sans-serif;
}
.wrap {
  padding: 0 16px;
}
.wrap > input:first-child {
  margin-top: 36px;
}

/* .aca {
  display: flex;
  justify-content: center;
  margin: 31px 0 10px 0;
  height: 40px;
  border: 1px solid #ccc;
  font-size: 15px;
}
.aca select {
  padding-left: 7px;
  width: 95%;
  height: 100%;
  line-height: 40px;
  border: 0;
} */

.now,
.wait {
  font-family: "NanumSquareRound", sans-serif;
  border-radius: 100px;
  padding: 0 8px;
  height: 26px;
  line-height: 26px;
  font-weight: 800;
}
.now {
  background: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  color: #586870;
}
.wait {
  color: #fff;
}

.isWait {
  height: 24px;
  color: white;
  display: inline-block;
  width: 100%;
}

.left {
  color: red;
}

.time label {
  border: 1px solid #ccc;
  width: 77px;
}
.time label.checked {
  background: #586870;
  border: 1px solid #586870;
  color: #fff;
}

.prevB {
  width: 49.5%;
  border: 0;
  background: #c0c0c0;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.nextB {
  width: 49.5%;
  border: 0;
  background: #61890e;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.centerB {
  background: #c9c9c9;
  height: 50px;
  color: #fff;
}
section option {
  width: 100%;
}
.inputwrapdiv {
  border: 1px solid #ccc;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*select 모듈*/

.css-yk16xz-control:hover {
  border-color: #ccc;
}
.css-2b097c-container {
  height: 50px;
  margin-bottom: 24px;
}
.css-yk16xz-control {
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 100%;
  border-radius: 0;
}
.css-1pahdxg-control {
  border-color: #ccc !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 100%;
}
.css-26l3qy-menu {
  border-radius: 0 !important;
  margin: 0 !important;
}
.css-26l3qy-menu > div {
    height: 30vh !important;
}

/*swal.fire 모듈*/
.swal2-popup {
  text-align: center;
  justify-content: flex-start;
  max-width: 500px !important;
}


@media all and (min-width: 600px) {
    .address_Cancel {
        width: 24px !important;
        height: 24px !important;
        right: 0px !important;
    }
}