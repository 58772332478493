.point {
  font-family: "NanumSquareRound", sans-serif;
  font-weight: 800;
  margin: 30px 0;
  color: #415B0A;
}
.copy {
  width: 70px;
  height: 100%;
  border: 0;
  background: #999;
  margin-left: 10px;
  float: right;
  color: #fff;
  line-height: 28px;
  font-size: 16px;
}
.success {
  height: 30px;
  margin-bottom: 20px;
}
.swal2-title {
  font-size: 17px !important;
  font-weight: 400 !important;
  position: absolute !important;
  top: 25px;
  width: 100%;
}
.swal2-popup {
  padding: 0;
  height: 120px;
  width: 88%;
}
.swal2-styled {
  width: 100%;
  margin: 0;
}
.swal2-styled.swal2-confirm {
  border-radius: 0 0 0.25em 0.25em !important;
  background-color: #fff !important;
  color: #2684ff !important;
  border-top: 1px solid #ccc !important;
  position: absolute;
  bottom: 0;
  height: 55px;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
