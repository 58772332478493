/* .result { padding:40px 16px 0 16px; display:flex; flex-direction:column; } */

.result > p {
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  line-height: 33px;
}
.box {
  margin: 12px 0px;
}

.result > .box > div > p {
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
}
.result > .box > div {
  text-align: left;
  line-height: 30px;
}
.result > .box > div > span {
  width: 75px;
  display: inline-block;
  color: #878787;
  font-size: 17px;
}
