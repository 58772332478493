.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: -1px;
}

.cell_Head {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
}

.cell_Body {
  display: flex;
  align-items: center;

  font-weight: bold;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  background-color: #627a86;
  cursor: move;
  padding: 0 10px;
  box-sizing: border-box;
}

.popup {
  display: none;
  position: absolute;
  top: 100px;
  left: 100px;
  width: 549px;
  height: 450px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.popup2 {
  height: 600px;
}

.input_Text {
  width: 255px;
  padding: 0 10px;
  margin-bottom: 10px;
  height: 50px;
  font-size: 17px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background: #fff;
}

.date-input {
  width: 255px !important;
  height: 50px !important;
  border-top: 0px !important;
  border-left: 0px !important;
}

.react-datepicker-ignore-onclickoutside {
  width: 255px !important;
  height: 50px !important;
  border-top: 0px !important;
  border-left: 0px !important;
}

.react-datepicker-wrapper {
  width: 255px;
  padding: 0px;
  margin-bottom: 10px;
  height: 50px;
  font-size: 17px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background: #fff;
}

.input_Text::placeholder {
  font-size: 17px;
}

.button1 {
  width: 117.5px;
  height: 30px;
  background-color: #627a86;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
}
/*
.wait {
    position: absolute;
    right: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 25px;
    background-color: #E60012;
    border-radius: 100px;
    color: white;
    box-sizing: border-box;
}
*/
.alert {
  position: relative;
  width: 255px;
  font-size: 13px;
  line-height: 22px;
  /* margin-bottom:5px; */
  color: red;
  text-align: left;
  margin-top: -30px;
  height: 30px;
  box-sizing: border-box;
}

@keyframes abc {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.setup {
  animation-name: abc;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.delIcon {
  display: none;
}
.classList:hover .delIcon {
  display: block;
}
#grade,
#class,
#modify_Class,
#modify_Grade {
  background-image: url("./image/drop.png");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
#class,
#modify_Class {
  letter-spacing: -1px;
}

.time_Setup_Radio:checked + div {
  background-color: #cde3ee;
}
#time {
  width: 255px;
  font-size: 17px;
  margin-bottom: 10px;
}

.scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}
.scroll2 {
  overflow-y: scroll;
}
.scroll2::-webkit-scrollbar {
  width: 12px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 100px;
  border: 3px solid #fff;
}
.scroll2::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.3);
  border: solid 3px transparent;
}
input[type="text"]::placeholder {
  font-size: 17px;
}
.addClass {
  width: 230px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: #627a86;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
