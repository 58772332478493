

.horizontal-menu .menu-item {
  padding: 0 13px;
  margin: 10px 3.5px;
  user-select: none;
  cursor: pointer;
  border: none;
  line-height: 30px;
  border: 1px solid #cccccc;
}
.horizontal-menu .menu-item {
  border-radius: 100px;
}

.menu-item-wrapper .menu-item.active { 
    border:1px solid #f07300; 

}
.horizontal-menu .menu-item:focus { outline:none; -webkit-tap-highlight-color : transparent;  }
.horizontal-menu .menu-item:active { border:1px solid #ccc !important; }
.horizontal-menu .menu-item-wrapper { outline:none; -webkit-tap-highlight-color : transparent; }


.menu-item.active {
  background-color: #f07300;
  color: #fff;
  line-height: 30px;
}
.menu-item-wrapper:active { border:0; padding:0; }
.menu-item-wrapper:focus { border:0; }
.menu-item:active { border:0; }
.menu-item:focus  { border:0; }

h3 {
  text-align: left;
  padding-left: 16px;
  line-height: 60px;
  font-size: 15px;
}

.gu {
  margin: 0 16px;
  border-top: 1px solid #c4c4c4;
}
.gu > div:first-child {
  width: 30%;
  max-height: 62vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.gu > div:last-child {
  width: 70%;
}
.gu > div:first-child .menu-item {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  background: #e8e8e8;
  border-bottom: 1px solid #cccccc;
  font-size: 15px;
  color: #878787;
}
.gu .menu-item.active {
  background-color: #fff !important;
  color: #333;
  font-size: 15px;
  color: #333 !important;
}
.gu > div:last-child .menu-item {
  padding-left: 10px;
  text-align: left;
  margin-left: 5%;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
  font-weight: 700;
}

.menu-item {
  cursor: pointer;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

::placeholder {
  line-height: 45px;
}
.timeSelect {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ccc;
  font-size: 15px;
  width: 100%;
  height: 45px;
  padding: 0 5px;
}
.selected:checked ~ label > b {
  color: #fff;
}
.selected:checked ~ label {
  background: #f07300;
  border: 1px solid #f07300;
}
