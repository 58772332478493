/*

Input styles
------------------
*/
.auto {
  position: relative;
}
.search-bar {
  width: 100%;
  font-size: 17px;
  color: rgb(52, 52, 52);
}

.search-bar::placeholder {
  font-size: 17px;
}

.search-bar:focus {
  outline: none;
}

/*
SearchBar container
*/

/*Cancel button styles*/

.cancel-btn {
  display: inline-block;
  height: 45px;
  width: 45px;
  right: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: none;
  border: none;
  outline: none;
  font-weight: 500;
  z-index: 1;
}

.cancel-btn2 {
  display: inline-block;
  height: 50px;
  width: 50px;
  right: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: none;
  border: none;
  outline: none;
  font-weight: 500;
  z-index: 1;
}

.search {
  display: flex;
  flex-direction: row;
}
.active {
  opacity: 1;
}
.inactive {
  opacity: 0;
}

/*
search results container div
*/

.search-results {
  /* display: flex;
  flex-direction: column; */
  background-color: white;
  max-height: 209px;
  padding: 10px;
  overflow-y: scroll;
  text-align: left;
  position: absolute;
  border: 1px solid #ccc;
  box-sizing: border-box;
  width: 100%;
  z-index: 999;
  transform: translateY(-11px);
}

/*
SearchPreview Component
*/

.search-preview {
  transition: 1s;
  display: flex;
  justify-content: space-between;
  padding: 5px 3px;
  padding-right: 10px;
  border-top: 1px solid;
  border-color: rgb(241, 241, 241);
  align-items: baseline;
  height: 50px;
}

.first1 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.second {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.sub-header {
  font-weight: 600;
  font-size: 10px;
  color: rgb(121, 121, 121);
}
.name {
  font-size: 15px;
  color: rgb(74, 74, 74);
  font-weight: 800;
  margin: 0rem 0;
}
.age {
  font-size: 15px;
  margin: 3px 0px;
  color: rgb(74, 74, 74);
  font-weight: 600;
}

.start {
  border-top: none;
}
