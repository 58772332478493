.acaImg > .slick-dots {
  position: absolute !important;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 10;
  margin: 0;
  list-style: none;
  text-align: center;
}
.acaImg > .slick-dots li.slick-active button:before {
  font-size: 15px;
  color: orange;
}

#imgSlider {
  width: 40%;
}

#imgContainer {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
}
#imgContainer > div:last-child { width:60%; }
.academy_warp { width:100% }
.call, .map { width:50%; height:40px; }
.call > button, .map > button { width:100%; height:100%; border:1px solid #B5B5B5; background:#fff; font-size:15px; }
.map { margin-left:-2px; }
.btnswrap_2 { padding-left:10px; }

.selectClass { padding:0; }

@media (max-width: 600px) {
  #imgSlider {
    width: 100%;
  }
  #imgContainer {
    flex-direction: column;
    text-align: center;
  }
  #imgContainer ~ p { padding:15px 16px; }
  #imgContainer > div:last-child { width:calc(100% - 36px); }
  .btnswrap_2 { border-bottom:1px solid #ccc;}
  .btnswrap_2 { padding-left:0; }
  .selectClass { padding:0 16px; }
  .btnswrap_2 > b { margin-top:26px; }
  .btnswrap_2 > div { margin-bottom:28px; }
  .btnwrap { padding:0 16px; }
}
